import { default as arbitrum_45walletRuhEygDg5xMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/arbitrum-wallet.vue?macro=true";
import { default as avalanche_45walletgIkGsioIAXMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/avalanche-wallet.vue?macro=true";
import { default as base_45walletn1NGml9t77Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/base-wallet.vue?macro=true";
import { default as binanceytEBToLrhyMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/binance.vue?macro=true";
import { default as coinbase_45wallete0XYxuEOYCMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/coinbase-wallet.vue?macro=true";
import { default as coinbasetxB4XCvJuLMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/coinbase.vue?macro=true";
import { default as domestic_45usdAMrBZivWCtMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/domestic-usd.vue?macro=true";
import { default as ethereum_45walletlaNdx74hmhMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/ethereum-wallet.vue?macro=true";
import { default as europelggfZDGqsnMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/europe.vue?macro=true";
import { default as gbpFt7RejdZqpMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/gbp.vue?macro=true";
import { default as international_45usd_45defaulti161nq3G6qMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/international-usd-default.vue?macro=true";
import { default as international_45usdtTMWXnnvb8Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/international-usd.vue?macro=true";
import { default as krakenw5awXgQTmkMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/kraken.vue?macro=true";
import { default as localKSI7jjQCuvMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/local.vue?macro=true";
import { default as ngny8q7Cv8WiyMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/ngn.vue?macro=true";
import { default as optimism_45walletrigZ7EaWwEMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/optimism-wallet.vue?macro=true";
import { default as polygon_45walleta3HpA44LD1Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/polygon-wallet.vue?macro=true";
import { default as addlVxkelt0LPMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add.vue?macro=true";
import { default as indexzhneokM50MMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/index.vue?macro=true";
import { default as _91documentType_93ocnpgkiALoMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/documents/[documentId]/[versionId]/[documentType].vue?macro=true";
import { default as index6VA9lUYq85Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/documents/index.vue?macro=true";
import { default as cryptoxfGiHEIjHMMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/withdraw/[accountId]/crypto.vue?macro=true";
import { default as fiatL01npWa50bMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/withdraw/[accountId]/fiat.vue?macro=true";
import { default as withdrawbWft92V9ieMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/withdraw.vue?macro=true";
import { default as activity3WxNYJTNeLMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/activity.vue?macro=true";
import { default as addressmDzl7ggTonMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/address.vue?macro=true";
import { default as developerUgUgCG6oZpMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/developer.vue?macro=true";
import { default as coinbaseSZ53qIx12YMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/link/callback/coinbase.vue?macro=true";
import { default as notificationsYcYwJ1iUNdMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/notifications.vue?macro=true";
import { default as _91organizationId_93QtmW1HNkvRMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId].vue?macro=true";
import { default as indexY0b2L6iyGnMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/index.vue?macro=true";
import { default as perksIU49NASczHMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/perks.vue?macro=true";
import { default as profilen8Pwhbl6iGMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/profile.vue?macro=true";
import { default as referralU4I3iU9CUWMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/referral.vue?macro=true";
import { default as riseidzm8f3JIUlmMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/riseid.vue?macro=true";
import { default as securityB56XkV0M4WMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/security.vue?macro=true";
import { default as sessionGTtMI9StyjMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/session.vue?macro=true";
import { default as settingsC750kb3LnyMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/settings.vue?macro=true";
import { default as _91uuid_93XxpzdFrvOuMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/[uuid].vue?macro=true";
import { default as _91token_93ShuIpb9xScMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/b2b/[token].vue?macro=true";
import { default as dashboardWJjWzCTLgCMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/dashboard.vue?macro=true";
import { default as complete87VB3BirlVMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/complete.vue?macro=true";
import { default as pendingtiiXPReLz1Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/pending.vue?macro=true";
import { default as integrationsmOBn1pMRGFMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/integrations.vue?macro=true";
import { default as _91id_93MK6Rr1isioMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/[id].vue?macro=true";
import { default as indexx45XUKNzBeMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/index.vue?macro=true";
import { default as indexH7s7VoQi2IMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/index.vue?macro=true";
import { default as tasksw7OSNNUQl3Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/tasks.vue?macro=true";
import { default as indexdoCySyU9LVMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/index.vue?macro=true";
import { default as _91nanoid_93RQT9bjdhX3Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/[nanoid].vue?macro=true";
import { default as indexOEHlqawl0nMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/index.vue?macro=true";
import { default as benefits9WafROMv07Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/benefits.vue?macro=true";
import { default as dashboardb2QJeVclRdMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/dashboard.vue?macro=true";
import { default as employee_settingsU1lk1IJdN5Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/employee_settings.vue?macro=true";
import { default as completelTzFMUScA8Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/history/complete.vue?macro=true";
import { default as pending09YyeiYoFaMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/history/pending.vue?macro=true";
import { default as pay_45slipsnm8dBxHEWBMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/pay-slips.vue?macro=true";
import { default as _91id_93rHo4dSY5ltMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/flat/[id].vue?macro=true";
import { default as indexbhownTRrTfMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/flat/index.vue?macro=true";
import { default as indexlfsWuGkJKkMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/index.vue?macro=true";
import { default as reimbursementsGv2aOMPonkMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/reimbursements.vue?macro=true";
import { default as tasksfOtmP8KUhMMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/tasks.vue?macro=true";
import { default as indexCtDZ3IxNS1Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/index.vue?macro=true";
import { default as _91nanoid_937qvMyQNuf0Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/invites/[nanoid].vue?macro=true";
import { default as jwtCYRnRSTltLMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/jwt.vue?macro=true";
import { default as callbackkbire8H1cPMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/oauth/callback.vue?macro=true";
import { default as administratorsMCgYyOCuL7Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/administrators.vue?macro=true";
import { default as dashboardTR6v119pUEMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/dashboard.vue?macro=true";
import { default as completeURf5XVm9OgMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/complete.vue?macro=true";
import { default as pendingxdyNSyCaCTMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/pending.vue?macro=true";
import { default as integrationsJUa3UUehrmMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/integrations.vue?macro=true";
import { default as directQY5z7EPGcWMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/direct.vue?macro=true";
import { default as warmEg0ldMDjhXMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/warm.vue?macro=true";
import { default as inviteseosDx0vt0UMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites.vue?macro=true";
import { default as address6FaydjR83BMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/address.vue?macro=true";
import { default as configsvZxJpRIci3Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/configs.vue?macro=true";
import { default as detailsMRwKyNFYViMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/details.vue?macro=true";
import { default as ownership77wlZXSr8dMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/ownership.vue?macro=true";
import { default as settingswA2CzE7t9ZMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings.vue?macro=true";
import { default as subscriptionZiG9GSZGvBMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/subscription.vue?macro=true";
import { default as taskslhsZk4QP7xMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/tasks.vue?macro=true";
import { default as addhr10VuE8xHMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/add.vue?macro=true";
import { default as indexgMjA4X8S1cMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/index.vue?macro=true";
import { default as balance_45transfernUjOANotMCMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/balance-transfer.vue?macro=true";
import { default as blockchain_45addressmMO8MiKJd3Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/blockchain-address.vue?macro=true";
import { default as usd_45domestic_45wire8NpVJawycbMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/usd-domestic-wire.vue?macro=true";
import { default as usd_45international_45wireS0wK4p1FuCMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/usd-international-wire.vue?macro=true";
import { default as depositD4qzmZUmSnMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit.vue?macro=true";
import { default as playground11uwecmtd9Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/playground.vue?macro=true";
import { default as _91_46_46_46id_93T6C3gbhU3eMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/[...id].vue?macro=true";
import { default as externalR7LoXmxMQiMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/external.vue?macro=true";
import { default as _2fakZc28vhZCDMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/2fa.vue?macro=true";
import { default as codebdvXRoAGIRMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/code.vue?macro=true";
import { default as indexZBhlBfyHnmMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/index.vue?macro=true";
import { default as sign_45out4DtIA5FQPbMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-out.vue?macro=true";
import { default as codeC8xVTd3LwUMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/code.vue?macro=true";
import { default as index1NNWYNrXkqMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/index.vue?macro=true";
import { default as waitingmZglsEDFsYMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/waiting.vue?macro=true";
import { default as administratorshIx989ibKtMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/administrators.vue?macro=true";
import { default as dashboard4tKrdezpi4Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboard.vue?macro=true";
import { default as dashboardOLD8WJDnYyVo1Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboardOLD.vue?macro=true";
import { default as blockchain_45addressAYVp9EVAaZMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/blockchain-address.vue?macro=true";
import { default as usd_45domestic_45wirePVnoVenGDMMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/usd-domestic-wire.vue?macro=true";
import { default as usd_45international_45wireEbS9BNOheHMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/usd-international-wire.vue?macro=true";
import { default as depositd3Ta0Xp99eMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit.vue?macro=true";
import { default as editxnXxx8cDUnMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/edit.vue?macro=true";
import { default as completeEwF20I90TCMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/complete.vue?macro=true";
import { default as pendingwAEyGUVdmZMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/pending.vue?macro=true";
import { default as integrationsbglHpLwUPwMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/integrations.vue?macro=true";
import { default as batchHnRdCbAarDMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/batch.vue?macro=true";
import { default as directZAutrD35HwMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/direct.vue?macro=true";
import { default as warmOq1tMu4bZGMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/warm.vue?macro=true";
import { default as invitesT9Of8EI5b5Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites.vue?macro=true";
import { default as benefitsVoUboNmW8TMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/benefits.vue?macro=true";
import { default as summarydDD0NVKiBGMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/summary.vue?macro=true";
import { default as _91userId_93TgkRDA0BVLMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId].vue?macro=true";
import { default as indexyKNwxC0vbdMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/index.vue?macro=true";
import { default as csv9XulV8reDWMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/csv.vue?macro=true";
import { default as indexyYfsRg5ygcMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/index.vue?macro=true";
import { default as indexVmhZEwxAltMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/index.vue?macro=true";
import { default as directhpPs8fI0mGMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/direct.vue?macro=true";
import { default as instantPq5U3wNu4UMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/instant.vue?macro=true";
import { default as milestoneloSZcqM2CgMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/milestone.vue?macro=true";
import { default as once70OU6tbzokMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/once.vue?macro=true";
import { default as recurringscq6Qw0es8Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/recurring.vue?macro=true";
import { default as _91userId_93zeAJ3NAOY7Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId].vue?macro=true";
import { default as payableaPOAL680NxMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payable.vue?macro=true";
import { default as employeesY7cY7fj5n5Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current/employees.vue?macro=true";
import { default as pausedJl36lxPAfIMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current/paused.vue?macro=true";
import { default as pending_actionskVziAAa4dIMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current/pending_actions.vue?macro=true";
import { default as currentRBZ5kvSytpMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current.vue?macro=true";
import { default as pay_slipsC2fMXjJKjIMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/pay_slips.vue?macro=true";
import { default as previousoUfVnM4g4RMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/previous.vue?macro=true";
import { default as reports4TQNfwSqOrMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/reports.vue?macro=true";
import { default as settingsI2neUR1sS1Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/settings.vue?macro=true";
import { default as endedkVpHDajszZMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/severance/ended.vue?macro=true";
import { default as payrollJg651qKRoIMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/severance/payroll.vue?macro=true";
import { default as severance2VpbItdPSYMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/severance.vue?macro=true";
import { default as tax_documentsjQh5SWvNuwMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/tax_documents.vue?macro=true";
import { default as payrollD8Rztk0YK0Meta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll.vue?macro=true";
import { default as settingsd83pJLBothMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/settings.vue?macro=true";
import { default as tasksJply05sjXBMeta } from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/tasks.vue?macro=true";
export default [
  {
    name: "scope-workspaceId-accounts-add",
    path: "/:scope()/:workspaceId()/accounts/add",
    meta: addlVxkelt0LPMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add.vue").then(m => m.default || m),
    children: [
  {
    name: "scope-workspaceId-accounts-add-arbitrum-wallet",
    path: "arbitrum-wallet",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/arbitrum-wallet.vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-accounts-add-avalanche-wallet",
    path: "avalanche-wallet",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/avalanche-wallet.vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-accounts-add-base-wallet",
    path: "base-wallet",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/base-wallet.vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-accounts-add-binance",
    path: "binance",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/binance.vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-accounts-add-coinbase-wallet",
    path: "coinbase-wallet",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/coinbase-wallet.vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-accounts-add-coinbase",
    path: "coinbase",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/coinbase.vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-accounts-add-domestic-usd",
    path: "domestic-usd",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/domestic-usd.vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-accounts-add-ethereum-wallet",
    path: "ethereum-wallet",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/ethereum-wallet.vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-accounts-add-europe",
    path: "europe",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/europe.vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-accounts-add-gbp",
    path: "gbp",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/gbp.vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-accounts-add-international-usd-default",
    path: "international-usd-default",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/international-usd-default.vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-accounts-add-international-usd",
    path: "international-usd",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/international-usd.vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-accounts-add-kraken",
    path: "kraken",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/kraken.vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-accounts-add-local",
    path: "local",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/local.vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-accounts-add-ngn",
    path: "ngn",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/ngn.vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-accounts-add-optimism-wallet",
    path: "optimism-wallet",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/optimism-wallet.vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-accounts-add-polygon-wallet",
    path: "polygon-wallet",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/add/polygon-wallet.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "scope-workspaceId-accounts",
    path: "/:scope()/:workspaceId()/accounts",
    meta: indexzhneokM50MMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-documents-documentId-versionId-documentType",
    path: "/:scope()/:workspaceId()/documents/:documentId()/:versionId()/:documentType()",
    meta: _91documentType_93ocnpgkiALoMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/documents/[documentId]/[versionId]/[documentType].vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-documents",
    path: "/:scope()/:workspaceId()/documents",
    meta: index6VA9lUYq85Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/documents/index.vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-withdraw",
    path: "/:scope()/:workspaceId()/withdraw",
    meta: withdrawbWft92V9ieMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/withdraw.vue").then(m => m.default || m),
    children: [
  {
    name: "scope-workspaceId-withdraw-accountId-crypto",
    path: ":accountId()/crypto",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/withdraw/[accountId]/crypto.vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-withdraw-accountId-fiat",
    path: ":accountId()/fiat",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/withdraw/[accountId]/fiat.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "account-activity",
    path: "/account/activity",
    meta: activity3WxNYJTNeLMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/activity.vue").then(m => m.default || m)
  },
  {
    name: "account-address",
    path: "/account/address",
    meta: addressmDzl7ggTonMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: "account-developer",
    path: "/account/developer",
    meta: developerUgUgCG6oZpMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/developer.vue").then(m => m.default || m)
  },
  {
    name: "account-link-callback-coinbase",
    path: "/account/link/callback/coinbase",
    meta: coinbaseSZ53qIx12YMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/link/callback/coinbase.vue").then(m => m.default || m)
  },
  {
    name: "account-notifications",
    path: "/account/notifications",
    meta: notificationsYcYwJ1iUNdMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/notifications.vue").then(m => m.default || m)
  },
  {
    name: "account-organizations-organizationId",
    path: "/account/organizations/:organizationId()",
    meta: _91organizationId_93QtmW1HNkvRMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId].vue").then(m => m.default || m)
  },
  {
    name: "account-organizations",
    path: "/account/organizations",
    meta: indexY0b2L6iyGnMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: "account-perks",
    path: "/account/perks",
    meta: perksIU49NASczHMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/perks.vue").then(m => m.default || m)
  },
  {
    name: "account-profile",
    path: "/account/profile",
    meta: profilen8Pwhbl6iGMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-referral",
    path: "/account/referral",
    meta: referralU4I3iU9CUWMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/referral.vue").then(m => m.default || m)
  },
  {
    name: "account-riseid",
    path: "/account/riseid",
    meta: riseidzm8f3JIUlmMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/riseid.vue").then(m => m.default || m)
  },
  {
    name: "account-security",
    path: "/account/security",
    meta: securityB56XkV0M4WMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/security.vue").then(m => m.default || m)
  },
  {
    name: "account-session",
    path: "/account/session",
    meta: sessionGTtMI9StyjMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/session.vue").then(m => m.default || m)
  },
  {
    name: "account-settings",
    path: "/account/settings",
    meta: settingsC750kb3LnyMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-uuid",
    path: "/auth/invite/:uuid()",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-b2b-token",
    path: "/auth/invite/b2b/:token()",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/b2b/[token].vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-dashboard",
    path: "/contractors/:workspaceId()/dashboard",
    meta: dashboardWJjWzCTLgCMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-history-complete",
    path: "/contractors/:workspaceId()/history/complete",
    meta: complete87VB3BirlVMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/complete.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-history-pending",
    path: "/contractors/:workspaceId()/history/pending",
    meta: pendingtiiXPReLz1Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/pending.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-integrations",
    path: "/contractors/:workspaceId()/integrations",
    meta: integrationsmOBn1pMRGFMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/integrations.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-receivable-flat-id",
    path: "/contractors/:workspaceId()/receivable/flat/:id()",
    meta: _91id_93MK6Rr1isioMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/[id].vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-receivable-flat",
    path: "/contractors/:workspaceId()/receivable/flat",
    meta: indexx45XUKNzBeMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-receivable",
    path: "/contractors/:workspaceId()/receivable",
    meta: indexH7s7VoQi2IMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-tasks",
    path: "/contractors/:workspaceId()/tasks",
    meta: tasksw7OSNNUQl3Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/tasks.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-time_entries",
    path: "/contractors/:workspaceId()/time_entries",
    meta: indexdoCySyU9LVMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-time_entries-invoice-nanoid",
    path: "/contractors/:workspaceId()/time_entries/invoice/:nanoid()",
    meta: _91nanoid_93RQT9bjdhX3Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/[nanoid].vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-time_entries-invoice",
    path: "/contractors/:workspaceId()/time_entries/invoice",
    meta: indexOEHlqawl0nMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/index.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-benefits",
    path: "/employees/:workspaceId()/benefits",
    meta: benefits9WafROMv07Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/benefits.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-dashboard",
    path: "/employees/:workspaceId()/dashboard",
    meta: dashboardb2QJeVclRdMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-employee_settings",
    path: "/employees/:workspaceId()/employee_settings",
    meta: employee_settingsU1lk1IJdN5Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/employee_settings.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-history-complete",
    path: "/employees/:workspaceId()/history/complete",
    meta: completelTzFMUScA8Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/history/complete.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-history-pending",
    path: "/employees/:workspaceId()/history/pending",
    meta: pending09YyeiYoFaMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/history/pending.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-pay-slips",
    path: "/employees/:workspaceId()/pay-slips",
    meta: pay_45slipsnm8dBxHEWBMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/pay-slips.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-receivable-flat-id",
    path: "/employees/:workspaceId()/receivable/flat/:id()",
    meta: _91id_93rHo4dSY5ltMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/flat/[id].vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-receivable-flat",
    path: "/employees/:workspaceId()/receivable/flat",
    meta: indexbhownTRrTfMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/flat/index.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-receivable",
    path: "/employees/:workspaceId()/receivable",
    meta: indexlfsWuGkJKkMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/index.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-reimbursements",
    path: "/employees/:workspaceId()/reimbursements",
    meta: reimbursementsGv2aOMPonkMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/reimbursements.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-tasks",
    path: "/employees/:workspaceId()/tasks",
    meta: tasksfOtmP8KUhMMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/tasks.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexCtDZ3IxNS1Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invites-nanoid",
    path: "/invites/:nanoid()",
    meta: _91nanoid_937qvMyQNuf0Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/invites/[nanoid].vue").then(m => m.default || m)
  },
  {
    name: "jwt",
    path: "/jwt",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/jwt.vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    meta: callbackkbire8H1cPMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-administrators",
    path: "/organizations/:workspaceId()/administrators",
    meta: administratorsMCgYyOCuL7Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/administrators.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-dashboard",
    path: "/organizations/:workspaceId()/dashboard",
    meta: dashboardTR6v119pUEMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-history-complete",
    path: "/organizations/:workspaceId()/history/complete",
    meta: completeURf5XVm9OgMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/complete.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-history-pending",
    path: "/organizations/:workspaceId()/history/pending",
    meta: pendingxdyNSyCaCTMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/pending.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-integrations",
    path: "/organizations/:workspaceId()/integrations",
    meta: integrationsJUa3UUehrmMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/integrations.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-invites",
    path: "/organizations/:workspaceId()/invites",
    meta: inviteseosDx0vt0UMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites.vue").then(m => m.default || m),
    children: [
  {
    name: "organizations-workspaceId-invites-direct",
    path: "direct",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/direct.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-invites-warm",
    path: "warm",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/warm.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organizations-workspaceId-settings",
    path: "/organizations/:workspaceId()/settings",
    meta: settingswA2CzE7t9ZMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "organizations-workspaceId-settings-address",
    path: "address",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/address.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-settings-configs",
    path: "configs",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/configs.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-settings-details",
    path: "details",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/details.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-settings-ownership",
    path: "ownership",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/ownership.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organizations-workspaceId-subscription",
    path: "/organizations/:workspaceId()/subscription",
    meta: subscriptionZiG9GSZGvBMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/subscription.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-tasks",
    path: "/organizations/:workspaceId()/tasks",
    meta: taskslhsZk4QP7xMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/tasks.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-teams-add",
    path: "/organizations/:workspaceId()/teams/add",
    meta: addhr10VuE8xHMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/add.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-teams",
    path: "/organizations/:workspaceId()/teams",
    meta: indexgMjA4X8S1cMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/index.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-treasury-balance-transfer",
    path: "/organizations/:workspaceId()/treasury/balance-transfer",
    meta: balance_45transfernUjOANotMCMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/balance-transfer.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-treasury-deposit",
    path: "/organizations/:workspaceId()/treasury/deposit",
    meta: depositD4qzmZUmSnMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit.vue").then(m => m.default || m),
    children: [
  {
    name: "organizations-workspaceId-treasury-deposit-blockchain-address",
    path: "blockchain-address",
    meta: blockchain_45addressmMO8MiKJd3Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/blockchain-address.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-treasury-deposit-usd-domestic-wire",
    path: "usd-domestic-wire",
    meta: usd_45domestic_45wire8NpVJawycbMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/usd-domestic-wire.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-treasury-deposit-usd-international-wire",
    path: "usd-international-wire",
    meta: usd_45international_45wireS0wK4p1FuCMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/usd-international-wire.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "playground",
    path: "/playground",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/playground.vue").then(m => m.default || m)
  },
  {
    name: "receipts-id",
    path: "/receipts/:id(.*)*",
    meta: _91_46_46_46id_93T6C3gbhU3eMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/[...id].vue").then(m => m.default || m)
  },
  {
    name: "receipts-external",
    path: "/receipts/external",
    meta: externalR7LoXmxMQiMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/external.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-2fa",
    path: "/sign-in/2fa",
    meta: _2fakZc28vhZCDMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/2fa.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-code",
    path: "/sign-in/code",
    meta: codebdvXRoAGIRMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/code.vue").then(m => m.default || m)
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: indexZBhlBfyHnmMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-out",
    path: "/sign-out",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-out.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-code",
    path: "/sign-up/code",
    meta: codeC8xVTd3LwUMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/code.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: index1NNWYNrXkqMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-waiting",
    path: "/sign-up/waiting",
    meta: waitingmZglsEDFsYMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/waiting.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-administrators",
    path: "/teams/:workspaceId()/administrators",
    meta: administratorshIx989ibKtMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/administrators.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-dashboard",
    path: "/teams/:workspaceId()/dashboard",
    meta: dashboard4tKrdezpi4Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-dashboardOLD",
    path: "/teams/:workspaceId()/dashboardOLD",
    meta: dashboardOLD8WJDnYyVo1Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboardOLD.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-deposit",
    path: "/teams/:workspaceId()/deposit",
    meta: depositd3Ta0Xp99eMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit.vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-deposit-blockchain-address",
    path: "blockchain-address",
    meta: blockchain_45addressAYVp9EVAaZMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/blockchain-address.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-deposit-usd-domestic-wire",
    path: "usd-domestic-wire",
    meta: usd_45domestic_45wirePVnoVenGDMMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/usd-domestic-wire.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-deposit-usd-international-wire",
    path: "usd-international-wire",
    meta: usd_45international_45wireEbS9BNOheHMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/usd-international-wire.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-edit",
    path: "/teams/:workspaceId()/edit",
    meta: editxnXxx8cDUnMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-history-complete",
    path: "/teams/:workspaceId()/history/complete",
    meta: completeEwF20I90TCMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/complete.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-history-pending",
    path: "/teams/:workspaceId()/history/pending",
    meta: pendingwAEyGUVdmZMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/pending.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-integrations",
    path: "/teams/:workspaceId()/integrations",
    meta: integrationsbglHpLwUPwMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/integrations.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-invites",
    path: "/teams/:workspaceId()/invites",
    meta: invitesT9Of8EI5b5Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites.vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-invites-batch",
    path: "batch",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/batch.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-invites-direct",
    path: "direct",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/direct.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-invites-warm",
    path: "warm",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/warm.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-members-userId",
    path: "/teams/:workspaceId()/members/:userId()",
    meta: _91userId_93TgkRDA0BVLMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId].vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-members-userId-benefits",
    path: "benefits",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/benefits.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-members-userId-summary",
    path: "summary",
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/summary.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-members",
    path: "/teams/:workspaceId()/members",
    meta: indexyKNwxC0vbdMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-batch-csv",
    path: "/teams/:workspaceId()/pay/batch/csv",
    meta: csv9XulV8reDWMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/csv.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-batch",
    path: "/teams/:workspaceId()/pay/batch",
    meta: indexyYfsRg5ygcMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay",
    path: "/teams/:workspaceId()/pay",
    meta: indexVmhZEwxAltMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId",
    path: "/teams/:workspaceId()/pay/individual/:userId()",
    meta: _91userId_93zeAJ3NAOY7Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId].vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-pay-individual-userId-direct",
    path: "direct",
    meta: directhpPs8fI0mGMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/direct.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-instant",
    path: "instant",
    meta: instantPq5U3wNu4UMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/instant.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-milestone",
    path: "milestone",
    meta: milestoneloSZcqM2CgMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/milestone.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-once",
    path: "once",
    meta: once70OU6tbzokMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/once.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-recurring",
    path: "recurring",
    meta: recurringscq6Qw0es8Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/recurring.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-payable",
    path: "/teams/:workspaceId()/payable",
    meta: payableaPOAL680NxMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payable.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll",
    path: "/teams/:workspaceId()/payroll",
    meta: payrollD8Rztk0YK0Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll.vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-payroll-current",
    path: "current",
    meta: currentRBZ5kvSytpMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current.vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-payroll-current-employees",
    path: "employees",
    meta: employeesY7cY7fj5n5Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current/employees.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll-current-paused",
    path: "paused",
    meta: pausedJl36lxPAfIMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current/paused.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll-current-pending_actions",
    path: "pending_actions",
    meta: pending_actionskVziAAa4dIMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current/pending_actions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-payroll-pay_slips",
    path: "pay_slips",
    meta: pay_slipsC2fMXjJKjIMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/pay_slips.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll-previous",
    path: "previous",
    meta: previousoUfVnM4g4RMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/previous.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll-reports",
    path: "reports",
    meta: reports4TQNfwSqOrMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/reports.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll-settings",
    path: "settings",
    meta: settingsI2neUR1sS1Meta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/settings.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll-severance",
    path: "severance",
    meta: severance2VpbItdPSYMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/severance.vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-payroll-severance-ended",
    path: "ended",
    meta: endedkVpHDajszZMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/severance/ended.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll-severance-payroll",
    path: "payroll",
    meta: payrollJg651qKRoIMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/severance/payroll.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-payroll-tax_documents",
    path: "tax_documents",
    meta: tax_documentsjQh5SWvNuwMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/tax_documents.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-settings",
    path: "/teams/:workspaceId()/settings",
    meta: settingsd83pJLBothMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/settings.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-tasks",
    path: "/teams/:workspaceId()/tasks",
    meta: tasksJply05sjXBMeta || {},
    component: () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/tasks.vue").then(m => m.default || m)
  }
]