import { defineStore } from "pinia";
import { useCookies } from "@vueuse/integrations/useCookies";

export type ClerkError = {
  code: "authentication_invalid";
  longMessage: string;
  message: string;
};

export const useSessionStore = defineStore("session", {
  state: (): {
    token: string | null;
    cleared: boolean;
  } => ({
    token: null,
    cleared: false,
  }),
  getters: {
    needSecondFactor(): boolean {
      if (!this.$clerk?.client) return false;
      return this.$clerk.client.signIn.status === "needs_second_factor";
    },
    sessionId(): string {
      if (!this.$clerk?.client?.sessions[0]) return "";
      if (!localStorage) return "";
      if (!localStorage.getItem("clerk-session-id")) return "";

      return localStorage.getItem("clerk-session-id") as string;
    },
  },
  actions: {
    isConnected(): boolean {
      const clerkUserStore = useClerkUserStore();
      return !!clerkUserStore.user?.primaryEmailAddress;
    },
    hasSession() {
      const clientSession = this.$clerk?.client?.sessions[0];
      return !this.cleared && clientSession;
    },
    getSession() {
      return this.$clerk?.client?.sessions[0];
    },
    async refreshSession() {
      const clientSession = this.$clerk?.client?.sessions[0];
      if (!clientSession) return;
      try {
        await this.$clerk.setSession(clientSession);
        localStorage.setItem("clerk-session-id", clientSession.id);
      } catch (e) {
        const clerkError: unknown = e;
        if (!clerkError) return;

        if (
          (clerkError as { errors: ClerkError[] }).errors[0].code ===
          "authentication_invalid"
        ) {
          this.$router.push("/sign-out");
        }

        throw new Error((e as Error).message);
      }
    },
    async refreshToken() {
      if (!this.$clerk.session) return;
      this.token = await this.$clerk.session.getToken();
    },
    async signOut() {
      this.cleared = true;
      const relationshipsStore = useRelationshipsStore();
      const onboardingStore = useOnboardingStore();
      relationshipsStore.relationships = [];
      onboardingStore.onboarding = { onboarded: false };
      const { remove } = useCookies([]);

      await this.$clerk.signOut();

      await remove("rise-user");
      await remove("__session");
      await remove("__clerk_db_jwt");
      await remove("__client_uat");
      await localStorage.removeItem("clerk-session-id");
    },
  },
});
