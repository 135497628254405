import { defineStore } from "pinia";
import type { z } from "zod";
import { useLocalStorage } from "@vueuse/core";
import type { Ref } from "vue";

import { schema } from "~/.nuxt/contracts/src/routes/dashboard/me";
import { schema as userSchema } from "~/.nuxt/contracts/src/routes/dashboard/user";
import { schema as userSessionSchema } from "~/.nuxt/contracts/src/routes/dashboard/user_sessions";

const getMeResponseSchema =
  schema["/dashboard/me"].get.response[200].shape.data;

const getUserSessionSchema =
  userSessionSchema["/dashboard/user_sessions/latest"].get.response[200].shape
    .data;
const getUserSessionSchemaPartial = getUserSessionSchema.deepPartial();

const getMeResponseSchemaPartial = getMeResponseSchema.deepPartial();
const getAvatarSignedUrlSchema =
  userSchema["/dashboard/user/avatar/sign-url"].post.response[200].shape.data;
const postAvatarSignedUrlSchema =
  userSchema["/dashboard/user/avatar/sign-url"].post.body;

export const useUserStore = defineStore("user", {
  state: (): {
    me: Ref<z.infer<typeof getMeResponseSchemaPartial>>;
    loading: boolean;
    signedUrlResponse: z.infer<typeof getAvatarSignedUrlSchema>;
    userSession: Ref<z.infer<typeof getUserSessionSchemaPartial>>;
    theme: string;
  } => ({
    me: useLocalStorage("pinia/dashboard/me", {}),
    loading: false,
    signedUrlResponse: {
      file_name: "",
      url: "",
    },
    userSession: {
      started_at: "2025-03-28T11:30:24.180Z",
      last_updated_at: null,
      nanoid: "…",
      country: "…",
      user_agent: "…",
      ip_address: "…",
      requests: [
        {
          created_at: "2025-03-28T11:30:24.180Z",
          description: "…",
          method: "…",
          url: "…",
          status: 1,
          attributes: {
            params: null,
            body: null,
            query: null,
          },
        },
      ],
    },
    theme: "light",
  }),
  getters: {
    isPayee(state) {
      return state.me.user?.type === "payee";
    },
    hasCompany(state) {
      return !!state.me.company;
    },
  },
  actions: {
    setTheme(theme: string) {
      this.theme = theme;
      localStorage.theme = theme;
      document.documentElement.classList.remove("dark");
      document.documentElement.classList.add(theme);
    },
    async getMe() {
      // if (this.me?.user?.nanoid) {
      //   return this.me;
      // }
      const response = await this.$client["/dashboard/me"].get();
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const responseJson = await response.json();
      this.me = getMeResponseSchema.parse(responseJson.data);
    },
    async getSignedUrl(body: z.infer<typeof postAvatarSignedUrlSchema>) {
      const response = await this.$client[
        "/dashboard/user/avatar/sign-url"
      ].post({
        json: body,
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const responseJson = await response.json();

      this.signedUrlResponse = getAvatarSignedUrlSchema.parse(
        responseJson.data
      );
    },
    async avatarUpdate(fileName: string) {
      const response = await this.$client["/dashboard/user/avatar/update"].post(
        {
          json: {
            file_name: fileName,
          },
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }
    },
    async sendSession() {
      const sessionStore = useSessionStore();
      if (sessionStore.sessionId.length <= 0) return;

      const response = await this.$client["/dashboard/user_sessions"].post({
        json: {
          clerk_session_id: sessionStore.sessionId,
        },
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
    },

    async getSession() {
      const sessionStore = useSessionStore();
      if (sessionStore.sessionId.length <= 0) return;

      const response = await this.$client[
        "/dashboard/user_sessions/latest"
      ].get();
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const structuredResponse = await response.json();

      this.userSession = structuredResponse.data;
      return structuredResponse;
    },
  },
});
