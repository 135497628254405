import { z } from 'zod'
import { recaptcha } from '../../formats.js'
import { defaultErrorResponses, defaultResponse } from '../../response.js'
import type { Schema } from '../../types.js'

const sendKlaviyoNotificationBody = z.discriminatedUnion('event', [
  z.object({
    event: z.enum(['PROVIDER_ADDED']),
    payload: z.object({
      provider_name: z.string(),
    }),
  }),
  z.object({
    event: z.enum(['MFA_ENABLED']),
    payload: z.object({
      org_name: z.string(),
    }),
  }),
])

export const schema = {
  '/klaviyo/notification': {
    post: {
      tags: ['Misc'],
      security: [{ bearerAuth: [] }],
      headers: recaptcha,
      summary: 'Send klaviyo notification',
      body: sendKlaviyoNotificationBody,
      response: {
        200: defaultResponse,
        ...defaultErrorResponses,
      },
    },
  },
} as const satisfies Schema
