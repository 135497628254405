import { z } from 'zod'
import { inviteNanoid } from '../../brands.js'
import { invites } from '../../codegen/zod/rise/invites.js'
import { selectable_users_onboarding } from '../../codegen/zod/rise/users_onboarding.js'
import { defaultErrorResponses, defaultResponse } from '../../response.js'
import type { Schema } from '../../types.js'
import { prefill } from '../dashboard/invites.js'

const getOnboardingResponse = defaultResponse.extend({
  data: z.object({
    onboarded: z.boolean(),
    moderation_status:
      selectable_users_onboarding.shape.moderation_status.optional(),
    step: selectable_users_onboarding.shape.step.optional(),
    onboard_role: selectable_users_onboarding.shape.role.optional(),
  }),
})

const getOnboardingInvitesResponse = defaultResponse.extend({
  data: z.array(
    z.object({
      role: invites.shape.role,
      nanoid: inviteNanoid,
      team: z
        .object({
          name: z.string().nullable(),
          avatar: z.string(),
        })
        .nullable(),
      company: z.object({
        name: z.string().min(1),
        avatar: z.string(),
        address: z.object({
          line_1: z.string(),
          city: z.string(),
          state: z.string(),
          country: z.string(),
          zip_code: z.string(),
          timezone: z.string(),
        }),
        owners: z
          .object({
            first_name: z.string(),
            last_name: z.string(),
          })
          .array(),
      }),
      inviter_name: z.string().min(1),
      prefill: prefill.partial(),
      created_at: z.string().datetime(),
    }),
  ),
})

export const schema = {
  '/onboarding/invites': {
    get: {
      tags: ['Onboarding'],
      security: [{ bearerAuth: [] }],
      summary: 'List invites',
      response: {
        200: getOnboardingInvitesResponse,
        ...defaultErrorResponses,
      },
    },
  },
  '/onboarding': {
    get: {
      tags: ['Onboarding'],
      security: [{ bearerAuth: [] }],
      summary: 'Get onboarding data',
      response: {
        200: getOnboardingResponse,
        ...defaultErrorResponses,
      },
    },
  },
} as const satisfies Schema
