import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

export default defineNuxtPlugin((nuxtApp) => {
  const app = nuxtApp.vueApp;
  const runtimeConfig = useRuntimeConfig();
  const router = useRouter();

  if (runtimeConfig.public.isLocalDevelopment) return;

  Sentry.init({
    app,
    dsn: "https://d1a281b586c4a7900c3ad49a3926478d@o417911.ingest.us.sentry.io/4508765510500352",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          "localhost",
          "dev-app.riseworks.dev",
          "app.staging-riseworks.io",
          "app.riseworks.io",
          /^\//,
        ],
      }),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    ignoreErrors: [
      "Not connected to a wallet.",
      "Wallet not authorized",
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource.",
      "TypeError: Load failed",
      "TypeError: Importing a module script failed.",
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    environment: runtimeConfig.public.NODE_ENV || "dev",
    beforeSend(event, hint) {
      if (event.exception) {
        console.error(
          `[Exeption handled by Sentry]: (${hint.originalException})`,
          { event, hint }
        );
      }
      return event;
    },
  });
  app.provide("sentry", {
    setContext: (n: any, context: any) => Sentry.setContext(n, context),
    setUser: (user: any) => Sentry.setUser(user),
    setTag: (tagName: string, value: string) => Sentry.setTag(tagName, value),
    addBreadcrumb: (breadcrumb: any) => Sentry.addBreadcrumb(breadcrumb),
    captureException: (exception: any) => Sentry.captureException(exception),
  });
});
