export default defineNuxtRouteMiddleware(async (to) => {
  if (
    typeof to.params.scope === "string" &&
    !["employees", "contractors", "organizations", "teams"].includes(
      to.params.scope
    )
  ) {
    return createError({ statusCode: 404, message: "Not Found" });
  }
});
