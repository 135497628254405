
	import { z } from 'zod'
	import { blockchainAddress } from './formats.js'


	export const risePaymentHandlerForwarderProcessTokenTransfersWithConfigValuesSchema = z.object({
			from: blockchainAddress,
		to: blockchainAddress,
		salt: z.string(),
		expires: z.string(),
		data: z.object({
			token: blockchainAddress,
		configs: z.array(z.object({
			amount: z.string(),
		transferType: z.string(),
		fixedOrPercent: z.string(),
		ramp: blockchainAddress,
		source: blockchainAddress,
		destination: blockchainAddress,
		offChainReference: z.string(),
		data: z.string(),
})),
}),
})
	export const risePaymentHandlerForwarderProcessTokenTransfersWithConfigTypedData = z.object({
ProcessTokenTransfersWithConfigForwardRequest: z.array(z.object({name: z.string(), type: z.string()})),
RisePaymentHandlerConfigRequest: z.array(z.object({name: z.string(), type: z.string()})),
RisePaymentHandlerConfig: z.array(z.object({name: z.string(), type: z.string()}))})
	export const risePaymentHandlerForwarderProcessTokenTransfersWithConfigTypedDataResponse = z.object({
							domain: z.object({
								name: z.string(),
								version: z.string(),
								chainId: z.number(),
								verifyingContract: blockchainAddress,
							}),
							types: risePaymentHandlerForwarderProcessTokenTransfersWithConfigTypedData,
							typed_data: risePaymentHandlerForwarderProcessTokenTransfersWithConfigValuesSchema,
							primary_type: z.string(),
						})
	export type RisePaymentHandlerForwarderProcessTokenTransfersWithConfigRequest = z.infer<typeof risePaymentHandlerForwarderProcessTokenTransfersWithConfigValuesSchema>
	


	export const risePaymentHandlerForwarderSetTransferRulesValuesSchema = z.object({
			from: blockchainAddress,
		to: blockchainAddress,
		salt: z.string(),
		expires: z.string(),
		data: z.object({
			token: blockchainAddress,
		configs: z.array(z.object({
			amount: z.string(),
		transferType: z.string(),
		fixedOrPercent: z.string(),
		ramp: blockchainAddress,
		source: blockchainAddress,
		destination: blockchainAddress,
		offChainReference: z.string(),
		data: z.string(),
})),
}),
})
	export const risePaymentHandlerForwarderSetTransferRulesTypedData = z.object({
SetTransferRulesForwardRequest: z.array(z.object({name: z.string(), type: z.string()})),
RisePaymentHandlerConfigRequest: z.array(z.object({name: z.string(), type: z.string()})),
RisePaymentHandlerConfig: z.array(z.object({name: z.string(), type: z.string()}))})
	export const risePaymentHandlerForwarderSetTransferRulesTypedDataResponse = z.object({
							domain: z.object({
								name: z.string(),
								version: z.string(),
								chainId: z.number(),
								verifyingContract: blockchainAddress,
							}),
							types: risePaymentHandlerForwarderSetTransferRulesTypedData,
							typed_data: risePaymentHandlerForwarderSetTransferRulesValuesSchema,
							primary_type: z.string(),
						})
	export type RisePaymentHandlerForwarderSetTransferRulesRequest = z.infer<typeof risePaymentHandlerForwarderSetTransferRulesValuesSchema>
	

	export const riseAccountForwarderCreatePaymentValuesSchema = z.object({
			from: blockchainAddress,
		to: blockchainAddress,
		salt: z.string(),
		expires: z.string(),
		data: z.object({
			id: z.string(),
		groupID: z.string(),
		payAtTime: z.string(),
		validMinutes: z.string(),
		payType: z.string(),
		token: blockchainAddress,
		recipient: blockchainAddress,
		amount: z.string(),
		data: z.string(),
}),
})
	export const riseAccountForwarderCreatePaymentTypedData = z.object({
CreatePaymentForwardRequest: z.array(z.object({name: z.string(), type: z.string()})),
RisePayment: z.array(z.object({name: z.string(), type: z.string()}))})
	export const riseAccountForwarderCreatePaymentTypedDataResponse = z.object({
							domain: z.object({
								name: z.string(),
								version: z.string(),
								chainId: z.number(),
								verifyingContract: blockchainAddress,
							}),
							types: riseAccountForwarderCreatePaymentTypedData,
							typed_data: riseAccountForwarderCreatePaymentValuesSchema,
							primary_type: z.string(),
						})
	export type RiseAccountForwarderCreatePaymentRequest = z.infer<typeof riseAccountForwarderCreatePaymentValuesSchema>
	


	export const riseAccountForwarderCreatePaymentAndExecuteValuesSchema = z.object({
			from: blockchainAddress,
		to: blockchainAddress,
		salt: z.string(),
		expires: z.string(),
		data: z.object({
			id: z.string(),
		groupID: z.string(),
		payAtTime: z.string(),
		validMinutes: z.string(),
		payType: z.string(),
		token: blockchainAddress,
		recipient: blockchainAddress,
		amount: z.string(),
		data: z.string(),
}),
})
	export const riseAccountForwarderCreatePaymentAndExecuteTypedData = z.object({
CreatePaymentForwardRequest: z.array(z.object({name: z.string(), type: z.string()})),
RisePayment: z.array(z.object({name: z.string(), type: z.string()}))})
	export const riseAccountForwarderCreatePaymentAndExecuteTypedDataResponse = z.object({
							domain: z.object({
								name: z.string(),
								version: z.string(),
								chainId: z.number(),
								verifyingContract: blockchainAddress,
							}),
							types: riseAccountForwarderCreatePaymentAndExecuteTypedData,
							typed_data: riseAccountForwarderCreatePaymentAndExecuteValuesSchema,
							primary_type: z.string(),
						})
	export type RiseAccountForwarderCreatePaymentAndExecuteRequest = z.infer<typeof riseAccountForwarderCreatePaymentAndExecuteValuesSchema>
	


	export const riseAccountForwarderCreatePaymentByScheduleValuesSchema = z.object({
			from: blockchainAddress,
		to: blockchainAddress,
		salt: z.string(),
		expires: z.string(),
		data: z.object({
			count: z.string(),
		minuteInterval: z.string(),
		payment: z.object({
			id: z.string(),
		groupID: z.string(),
		payAtTime: z.string(),
		validMinutes: z.string(),
		payType: z.string(),
		token: blockchainAddress,
		recipient: blockchainAddress,
		amount: z.string(),
		data: z.string(),
}),
}),
})
	export const riseAccountForwarderCreatePaymentByScheduleTypedData = z.object({
CreatePaymentByScheduleForwardRequest: z.array(z.object({name: z.string(), type: z.string()})),
RisePaymentScheduleRequest: z.array(z.object({name: z.string(), type: z.string()})),
RisePayment: z.array(z.object({name: z.string(), type: z.string()}))})
	export const riseAccountForwarderCreatePaymentByScheduleTypedDataResponse = z.object({
							domain: z.object({
								name: z.string(),
								version: z.string(),
								chainId: z.number(),
								verifyingContract: blockchainAddress,
							}),
							types: riseAccountForwarderCreatePaymentByScheduleTypedData,
							typed_data: riseAccountForwarderCreatePaymentByScheduleValuesSchema,
							primary_type: z.string(),
						})
	export type RiseAccountForwarderCreatePaymentByScheduleRequest = z.infer<typeof riseAccountForwarderCreatePaymentByScheduleValuesSchema>
	


	export const riseAccountForwarderCreatePaymentsValuesSchema = z.object({
			from: blockchainAddress,
		to: blockchainAddress,
		salt: z.string(),
		expires: z.string(),
		data: z.object({
			payments: z.array(z.object({
			id: z.string(),
		groupID: z.string(),
		payAtTime: z.string(),
		validMinutes: z.string(),
		payType: z.string(),
		token: blockchainAddress,
		recipient: blockchainAddress,
		amount: z.string(),
		data: z.string(),
})),
}),
})
	export const riseAccountForwarderCreatePaymentsTypedData = z.object({
CreatePaymentsForwardRequest: z.array(z.object({name: z.string(), type: z.string()})),
RisePaymentsRequest: z.array(z.object({name: z.string(), type: z.string()})),
RisePayment: z.array(z.object({name: z.string(), type: z.string()}))})
	export const riseAccountForwarderCreatePaymentsTypedDataResponse = z.object({
							domain: z.object({
								name: z.string(),
								version: z.string(),
								chainId: z.number(),
								verifyingContract: blockchainAddress,
							}),
							types: riseAccountForwarderCreatePaymentsTypedData,
							typed_data: riseAccountForwarderCreatePaymentsValuesSchema,
							primary_type: z.string(),
						})
	export type RiseAccountForwarderCreatePaymentsRequest = z.infer<typeof riseAccountForwarderCreatePaymentsValuesSchema>
	


	export const riseAccountForwarderCreatePaymentsAndExecuteValuesSchema = z.object({
			from: blockchainAddress,
		to: blockchainAddress,
		salt: z.string(),
		expires: z.string(),
		data: z.object({
			payments: z.array(z.object({
			id: z.string(),
		groupID: z.string(),
		payAtTime: z.string(),
		validMinutes: z.string(),
		payType: z.string(),
		token: blockchainAddress,
		recipient: blockchainAddress,
		amount: z.string(),
		data: z.string(),
})),
}),
})
	export const riseAccountForwarderCreatePaymentsAndExecuteTypedData = z.object({
CreatePaymentsForwardRequest: z.array(z.object({name: z.string(), type: z.string()})),
RisePaymentsRequest: z.array(z.object({name: z.string(), type: z.string()})),
RisePayment: z.array(z.object({name: z.string(), type: z.string()}))})
	export const riseAccountForwarderCreatePaymentsAndExecuteTypedDataResponse = z.object({
							domain: z.object({
								name: z.string(),
								version: z.string(),
								chainId: z.number(),
								verifyingContract: blockchainAddress,
							}),
							types: riseAccountForwarderCreatePaymentsAndExecuteTypedData,
							typed_data: riseAccountForwarderCreatePaymentsAndExecuteValuesSchema,
							primary_type: z.string(),
						})
	export type RiseAccountForwarderCreatePaymentsAndExecuteRequest = z.infer<typeof riseAccountForwarderCreatePaymentsAndExecuteValuesSchema>
	


	export const riseAccountForwarderIntentPaymentToScheduledValuesSchema = z.object({
			from: blockchainAddress,
		to: blockchainAddress,
		salt: z.string(),
		expires: z.string(),
		data: z.object({
			paymentIDs: z.array(z.string()),
		payAtTime: z.array(z.string()),
}),
})
	export const riseAccountForwarderIntentPaymentToScheduledTypedData = z.object({
IntentPaymentToScheduledForwardRequest: z.array(z.object({name: z.string(), type: z.string()})),
RiseIntentPaymentsToScheduledRequest: z.array(z.object({name: z.string(), type: z.string()}))})
	export const riseAccountForwarderIntentPaymentToScheduledTypedDataResponse = z.object({
							domain: z.object({
								name: z.string(),
								version: z.string(),
								chainId: z.number(),
								verifyingContract: blockchainAddress,
							}),
							types: riseAccountForwarderIntentPaymentToScheduledTypedData,
							typed_data: riseAccountForwarderIntentPaymentToScheduledValuesSchema,
							primary_type: z.string(),
						})
	export type RiseAccountForwarderIntentPaymentToScheduledRequest = z.infer<typeof riseAccountForwarderIntentPaymentToScheduledValuesSchema>
	


	export const riseAccountForwarderIntentPaymentToScheduledAndExecuteValuesSchema = z.object({
			from: blockchainAddress,
		to: blockchainAddress,
		salt: z.string(),
		expires: z.string(),
		data: z.object({
			paymentIDs: z.array(z.string()),
		payAtTime: z.array(z.string()),
}),
})
	export const riseAccountForwarderIntentPaymentToScheduledAndExecuteTypedData = z.object({
IntentPaymentToScheduledForwardRequest: z.array(z.object({name: z.string(), type: z.string()})),
RiseIntentPaymentsToScheduledRequest: z.array(z.object({name: z.string(), type: z.string()}))})
	export const riseAccountForwarderIntentPaymentToScheduledAndExecuteTypedDataResponse = z.object({
							domain: z.object({
								name: z.string(),
								version: z.string(),
								chainId: z.number(),
								verifyingContract: blockchainAddress,
							}),
							types: riseAccountForwarderIntentPaymentToScheduledAndExecuteTypedData,
							typed_data: riseAccountForwarderIntentPaymentToScheduledAndExecuteValuesSchema,
							primary_type: z.string(),
						})
	export type RiseAccountForwarderIntentPaymentToScheduledAndExecuteRequest = z.infer<typeof riseAccountForwarderIntentPaymentToScheduledAndExecuteValuesSchema>
	


	export const riseAccountForwarderRemovePaymentsValuesSchema = z.object({
			from: blockchainAddress,
		to: blockchainAddress,
		salt: z.string(),
		expires: z.string(),
		data: z.object({
			paymentIDs: z.array(z.string()),
}),
})
	export const riseAccountForwarderRemovePaymentsTypedData = z.object({
RemovePaymentsForwardRequest: z.array(z.object({name: z.string(), type: z.string()})),
RiseRemovePaymentsRequest: z.array(z.object({name: z.string(), type: z.string()}))})
	export const riseAccountForwarderRemovePaymentsTypedDataResponse = z.object({
							domain: z.object({
								name: z.string(),
								version: z.string(),
								chainId: z.number(),
								verifyingContract: blockchainAddress,
							}),
							types: riseAccountForwarderRemovePaymentsTypedData,
							typed_data: riseAccountForwarderRemovePaymentsValuesSchema,
							primary_type: z.string(),
						})
	export type RiseAccountForwarderRemovePaymentsRequest = z.infer<typeof riseAccountForwarderRemovePaymentsValuesSchema>
	


	export const riseAccountForwarderRemovePaymentsByGroupIDValuesSchema = z.object({
			from: blockchainAddress,
		to: blockchainAddress,
		salt: z.string(),
		expires: z.string(),
		data: z.object({
			groupID: z.string(),
		idx: z.string(),
		count: z.string(),
}),
})
	export const riseAccountForwarderRemovePaymentsByGroupIDTypedData = z.object({
RemovePaymentsByGroupIDForwardRequest: z.array(z.object({name: z.string(), type: z.string()})),
RiseRemovePaymentByGroupRequest: z.array(z.object({name: z.string(), type: z.string()}))})
	export const riseAccountForwarderRemovePaymentsByGroupIDTypedDataResponse = z.object({
							domain: z.object({
								name: z.string(),
								version: z.string(),
								chainId: z.number(),
								verifyingContract: blockchainAddress,
							}),
							types: riseAccountForwarderRemovePaymentsByGroupIDTypedData,
							typed_data: riseAccountForwarderRemovePaymentsByGroupIDValuesSchema,
							primary_type: z.string(),
						})
	export type RiseAccountForwarderRemovePaymentsByGroupIDRequest = z.infer<typeof riseAccountForwarderRemovePaymentsByGroupIDValuesSchema>
	


	export const riseAccountForwarderSendEtherValuesSchema = z.object({
			from: blockchainAddress,
		to: blockchainAddress,
		salt: z.string(),
		expires: z.string(),
		data: z.object({
			recipient: blockchainAddress,
		amount: z.string(),
}),
})
	export const riseAccountForwarderSendEtherTypedData = z.object({
SendEtherForwardRequest: z.array(z.object({name: z.string(), type: z.string()})),
RiseEtherTransferRequest: z.array(z.object({name: z.string(), type: z.string()}))})
	export const riseAccountForwarderSendEtherTypedDataResponse = z.object({
							domain: z.object({
								name: z.string(),
								version: z.string(),
								chainId: z.number(),
								verifyingContract: blockchainAddress,
							}),
							types: riseAccountForwarderSendEtherTypedData,
							typed_data: riseAccountForwarderSendEtherValuesSchema,
							primary_type: z.string(),
						})
	export type RiseAccountForwarderSendEtherRequest = z.infer<typeof riseAccountForwarderSendEtherValuesSchema>
	


	export const riseAccountForwarderSetRolesValuesSchema = z.object({
			from: blockchainAddress,
		to: blockchainAddress,
		salt: z.string(),
		expires: z.string(),
		data: z.array(z.object({
			role: z.string(),
		account: blockchainAddress,
})),
})
	export const riseAccountForwarderSetRolesTypedData = z.object({
SetRolesForwardRequest: z.array(z.object({name: z.string(), type: z.string()})),
SetRole: z.array(z.object({name: z.string(), type: z.string()}))})
	export const riseAccountForwarderSetRolesTypedDataResponse = z.object({
							domain: z.object({
								name: z.string(),
								version: z.string(),
								chainId: z.number(),
								verifyingContract: blockchainAddress,
							}),
							types: riseAccountForwarderSetRolesTypedData,
							typed_data: riseAccountForwarderSetRolesValuesSchema,
							primary_type: z.string(),
						})
	export type RiseAccountForwarderSetRolesRequest = z.infer<typeof riseAccountForwarderSetRolesValuesSchema>
	


	export const riseAccountForwarderSetTokenTransferApprovalValuesSchema = z.object({
			from: blockchainAddress,
		to: blockchainAddress,
		salt: z.string(),
		expires: z.string(),
		data: z.object({
			token: blockchainAddress,
		spender: blockchainAddress,
		amount: z.string(),
}),
})
	export const riseAccountForwarderSetTokenTransferApprovalTypedData = z.object({
SetTokenTransferApprovalForwardRequest: z.array(z.object({name: z.string(), type: z.string()})),
RiseTokenApprovalRequest: z.array(z.object({name: z.string(), type: z.string()}))})
	export const riseAccountForwarderSetTokenTransferApprovalTypedDataResponse = z.object({
							domain: z.object({
								name: z.string(),
								version: z.string(),
								chainId: z.number(),
								verifyingContract: blockchainAddress,
							}),
							types: riseAccountForwarderSetTokenTransferApprovalTypedData,
							typed_data: riseAccountForwarderSetTokenTransferApprovalValuesSchema,
							primary_type: z.string(),
						})
	export type RiseAccountForwarderSetTokenTransferApprovalRequest = z.infer<typeof riseAccountForwarderSetTokenTransferApprovalValuesSchema>
	


	export const riseAccountForwarderSetTransactionLimitsValuesSchema = z.object({
			from: blockchainAddress,
		to: blockchainAddress,
		salt: z.string(),
		expires: z.string(),
		data: z.object({
			spender: blockchainAddress,
		token: blockchainAddress,
		dailyLimit: z.string(),
		transactionLimit: z.string(),
}),
})
	export const riseAccountForwarderSetTransactionLimitsTypedData = z.object({
SetTransactionLimitsForwardRequest: z.array(z.object({name: z.string(), type: z.string()})),
RiseTransactionLimitRequest: z.array(z.object({name: z.string(), type: z.string()}))})
	export const riseAccountForwarderSetTransactionLimitsTypedDataResponse = z.object({
							domain: z.object({
								name: z.string(),
								version: z.string(),
								chainId: z.number(),
								verifyingContract: blockchainAddress,
							}),
							types: riseAccountForwarderSetTransactionLimitsTypedData,
							typed_data: riseAccountForwarderSetTransactionLimitsValuesSchema,
							primary_type: z.string(),
						})
	export type RiseAccountForwarderSetTransactionLimitsRequest = z.infer<typeof riseAccountForwarderSetTransactionLimitsValuesSchema>
	


	export const riseAccountForwarderTokenTransferValuesSchema = z.object({
			from: blockchainAddress,
		to: blockchainAddress,
		salt: z.string(),
		expires: z.string(),
		data: z.object({
			token: blockchainAddress,
		from: blockchainAddress,
		to: blockchainAddress,
		amount: z.string(),
}),
})
	export const riseAccountForwarderTokenTransferTypedData = z.object({
TokenTransferForwardRequest: z.array(z.object({name: z.string(), type: z.string()})),
RiseTokenTransferRequest: z.array(z.object({name: z.string(), type: z.string()}))})
	export const riseAccountForwarderTokenTransferTypedDataResponse = z.object({
							domain: z.object({
								name: z.string(),
								version: z.string(),
								chainId: z.number(),
								verifyingContract: blockchainAddress,
							}),
							types: riseAccountForwarderTokenTransferTypedData,
							typed_data: riseAccountForwarderTokenTransferValuesSchema,
							primary_type: z.string(),
						})
	export type RiseAccountForwarderTokenTransferRequest = z.infer<typeof riseAccountForwarderTokenTransferValuesSchema>
	

	export const riseIDForwarderApproveValuesSchema = z.object({
			from: blockchainAddress,
		to: blockchainAddress,
		salt: z.string(),
		expires: z.string(),
		data: z.object({
			token: blockchainAddress,
		spender: blockchainAddress,
		amount: z.string(),
}),
})
	export const riseIDForwarderApproveTypedData = z.object({
ApproveForwardRequest: z.array(z.object({name: z.string(), type: z.string()})),
Approve: z.array(z.object({name: z.string(), type: z.string()}))})
	export const riseIDForwarderApproveTypedDataResponse = z.object({
							domain: z.object({
								name: z.string(),
								version: z.string(),
								chainId: z.number(),
								verifyingContract: blockchainAddress,
							}),
							types: riseIDForwarderApproveTypedData,
							typed_data: riseIDForwarderApproveValuesSchema,
							primary_type: z.string(),
						})
	export type RiseIDForwarderApproveRequest = z.infer<typeof riseIDForwarderApproveValuesSchema>
	


	export const riseIDForwarderCallValuesSchema = z.object({
			from: blockchainAddress,
		to: blockchainAddress,
		salt: z.string(),
		expires: z.string(),
		data: z.object({
			to: blockchainAddress,
		method: z.string(),
		data: z.string(),
}),
})
	export const riseIDForwarderCallTypedData = z.object({
CallForwardRequest: z.array(z.object({name: z.string(), type: z.string()})),
Execution: z.array(z.object({name: z.string(), type: z.string()}))})
	export const riseIDForwarderCallTypedDataResponse = z.object({
							domain: z.object({
								name: z.string(),
								version: z.string(),
								chainId: z.number(),
								verifyingContract: blockchainAddress,
							}),
							types: riseIDForwarderCallTypedData,
							typed_data: riseIDForwarderCallValuesSchema,
							primary_type: z.string(),
						})
	export type RiseIDForwarderCallRequest = z.infer<typeof riseIDForwarderCallValuesSchema>
	


	export const riseIDForwarderExecuteValuesSchema = z.object({
			from: blockchainAddress,
		to: blockchainAddress,
		salt: z.string(),
		expires: z.string(),
		data: z.object({
			to: blockchainAddress,
		method: z.string(),
		data: z.string(),
}),
})
	export const riseIDForwarderExecuteTypedData = z.object({
ExecuteForwardRequest: z.array(z.object({name: z.string(), type: z.string()})),
Execution: z.array(z.object({name: z.string(), type: z.string()}))})
	export const riseIDForwarderExecuteTypedDataResponse = z.object({
							domain: z.object({
								name: z.string(),
								version: z.string(),
								chainId: z.number(),
								verifyingContract: blockchainAddress,
							}),
							types: riseIDForwarderExecuteTypedData,
							typed_data: riseIDForwarderExecuteValuesSchema,
							primary_type: z.string(),
						})
	export type RiseIDForwarderExecuteRequest = z.infer<typeof riseIDForwarderExecuteValuesSchema>
	


	export const riseIDForwarderSetDataValuesSchema = z.object({
			from: blockchainAddress,
		to: blockchainAddress,
		salt: z.string(),
		expires: z.string(),
		data: z.object({
			dataKey: z.string(),
		dataValue: z.string(),
}),
})
	export const riseIDForwarderSetDataTypedData = z.object({
SetDataForwardRequest: z.array(z.object({name: z.string(), type: z.string()})),
Dataset: z.array(z.object({name: z.string(), type: z.string()}))})
	export const riseIDForwarderSetDataTypedDataResponse = z.object({
							domain: z.object({
								name: z.string(),
								version: z.string(),
								chainId: z.number(),
								verifyingContract: blockchainAddress,
							}),
							types: riseIDForwarderSetDataTypedData,
							typed_data: riseIDForwarderSetDataValuesSchema,
							primary_type: z.string(),
						})
	export type RiseIDForwarderSetDataRequest = z.infer<typeof riseIDForwarderSetDataValuesSchema>
	


	export const riseIDForwarderSetRolesValuesSchema = z.object({
			from: blockchainAddress,
		to: blockchainAddress,
		salt: z.string(),
		expires: z.string(),
		data: z.array(z.object({
			role: z.string(),
		account: blockchainAddress,
})),
})
	export const riseIDForwarderSetRolesTypedData = z.object({
SetRolesForwardRequest: z.array(z.object({name: z.string(), type: z.string()})),
SetRole: z.array(z.object({name: z.string(), type: z.string()}))})
	export const riseIDForwarderSetRolesTypedDataResponse = z.object({
							domain: z.object({
								name: z.string(),
								version: z.string(),
								chainId: z.number(),
								verifyingContract: blockchainAddress,
							}),
							types: riseIDForwarderSetRolesTypedData,
							typed_data: riseIDForwarderSetRolesValuesSchema,
							primary_type: z.string(),
						})
	export type RiseIDForwarderSetRolesRequest = z.infer<typeof riseIDForwarderSetRolesValuesSchema>
	


	export const riseIDForwarderTransferValuesSchema = z.object({
			from: blockchainAddress,
		to: blockchainAddress,
		salt: z.string(),
		expires: z.string(),
		data: z.object({
			token: blockchainAddress,
		to: blockchainAddress,
		amount: z.string(),
}),
})
	export const riseIDForwarderTransferTypedData = z.object({
TransferForwardRequest: z.array(z.object({name: z.string(), type: z.string()})),
Transfer: z.array(z.object({name: z.string(), type: z.string()}))})
	export const riseIDForwarderTransferTypedDataResponse = z.object({
							domain: z.object({
								name: z.string(),
								version: z.string(),
								chainId: z.number(),
								verifyingContract: blockchainAddress,
							}),
							types: riseIDForwarderTransferTypedData,
							typed_data: riseIDForwarderTransferValuesSchema,
							primary_type: z.string(),
						})
	export type RiseIDForwarderTransferRequest = z.infer<typeof riseIDForwarderTransferValuesSchema>
	


	export const riseIDForwarderTransferFromValuesSchema = z.object({
			from: blockchainAddress,
		to: blockchainAddress,
		salt: z.string(),
		expires: z.string(),
		data: z.object({
			token: blockchainAddress,
		from: blockchainAddress,
		to: blockchainAddress,
		amount: z.string(),
}),
})
	export const riseIDForwarderTransferFromTypedData = z.object({
TransferFromForwardRequest: z.array(z.object({name: z.string(), type: z.string()})),
TransferFrom: z.array(z.object({name: z.string(), type: z.string()}))})
	export const riseIDForwarderTransferFromTypedDataResponse = z.object({
							domain: z.object({
								name: z.string(),
								version: z.string(),
								chainId: z.number(),
								verifyingContract: blockchainAddress,
							}),
							types: riseIDForwarderTransferFromTypedData,
							typed_data: riseIDForwarderTransferFromValuesSchema,
							primary_type: z.string(),
						})
	export type RiseIDForwarderTransferFromRequest = z.infer<typeof riseIDForwarderTransferFromValuesSchema>
	