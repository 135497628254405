import { z } from 'zod'
import { companyNanoid, userNanoid } from '../../brands.js'
import { users_onboarding } from '../../codegen/zod/rise/users_onboarding.js'
import { blockchainAddress } from '../../formats.js'
import { defaultErrorResponses, defaultResponse } from '../../response.js'
import type { Schema } from '../../types.js'

const getMeResponse = defaultResponse.extend({
  data: z.object({
    user: z.object({
      nanoid: userNanoid,
      riseid: blockchainAddress,
      rise_accounts: z.array(blockchainAddress),
      type: z.enum(['user', 'payer', 'payee', 'viewer']),
      avatar: z.string(),
      first_name: z.string(),
      middle_name: z.string(),
      last_name: z.string(),
      has_password: z.boolean().optional(),
      has_iframe_rsk: z.boolean().optional(),
    }),
    onboard: z.object({
      role: users_onboarding.shape.role.removeDefault().unwrap(),
    }),
    address: z.object({
      line_1: z.string(),
      line_2: z.string(),
      city: z.string(),
      state: z.string(),
      country: z.string(),
      zip_code: z.string(),
      timezone: z.string(),
    }),
    company: z
      .object({
        nanoid: companyNanoid,
        rise_accounts: z.array(blockchainAddress),
        avatar: z.string(),
        name: z.string(),
      })
      .optional(),
  }),
})
// example schema
export const schema = {
  '/dashboard/me': {
    get: {
      tags: ['Dashboard'],
      security: [{ bearerAuth: [] }],
      summary: 'Get logged user data',
      querystring: z.object({ app: z.string().optional() }),
      response: {
        200: getMeResponse,
        ...defaultErrorResponses,
      },
    },
  },
} as const satisfies Schema
