import validate from "/data/runners/one/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@parcel+watcher@2.5.0_@types+node@22.13.10_bufferutil@4.0.9_db0@0.2.1_eslin_cae75c569e017aa238f57f071e372c70/node_modules/nuxt/dist/pages/runtime/validate.js";
import query_45global from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/middleware/query.global.ts";
import scope_45global from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/middleware/scope.global.ts";
import session_45global from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/middleware/session.global.ts";
import workspaces_45global from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/app/middleware/workspaces.global.ts";
import manifest_45route_45rule from "/data/runners/one/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@parcel+watcher@2.5.0_@types+node@22.13.10_bufferutil@4.0.9_db0@0.2.1_eslin_cae75c569e017aa238f57f071e372c70/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  query_45global,
  scope_45global,
  session_45global,
  workspaces_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}