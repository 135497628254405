import type { ActionResponse, RSK } from "./types";
import { generateKey, getKey } from "./webauthn";

export const iframe = document.createElement("iframe");
iframe.style.display = "none";
iframe.setAttribute(
  "allow",
  "publickey-credentials-get *;publickey-credentials-create *"
);
document.body.appendChild(iframe);
const rskWindow = iframe.contentWindow as Window;

function responsePromise(type: string) {
  return new Promise((resolve, reject) => {
    const listener = (e: ActionResponse) => {
      if (e.data.type === `${type}-response`) {
        window.removeEventListener("message", listener);
        if (e.data.error) {
          reject(e.data.error);
        } else {
          resolve(e.data.data);
        }
      }
    };
    window.addEventListener("message", listener);
  });
}

type PostMessageParam = string | undefined | null;

let _token: PostMessageParam;
let _isRskIframe: boolean;
// const useTopLevelDomainPasskey = {
//   production: new Date("3/22/2025"),
//   staging: new Date("3/19/2025"),
//   dev: new Date("3/13/2025"),
// };

export default (): RSK => {
  const sdk: any = {
    create: async (email: string) => {
      // For any new users, we generate a passkey at the top level domain
      const pkId = await generateKey(email);
      return new Promise((resolve, reject) => {
        const type = "rsk-create";
        rskWindow.postMessage(
          { type, data: { email, pkId, token: _token } },
          "*"
        );
        responsePromise(type).then(resolve).catch(reject);
      });
    },
    connect: async (forceTld = false) => {
      let pkId = "";

      if (!_isRskIframe || forceTld) {
        pkId = await getKey();
      }
      return new Promise((resolve, reject) => {
        const type = "rsk-connect";
        rskWindow.postMessage({ type, data: { pkId, token: _token } }, "*");
        responsePromise(type).then(resolve).catch(reject);
      });
    },
    signMessage: async (message: string) => {
      return new Promise((resolve, reject) => {
        const type = "rsk-signMessage";
        rskWindow.postMessage({ type, data: { message } }, "*");
        responsePromise(type).then(resolve).catch(reject);
      });
    },
    generateKey,
    setup: (
      url: string,
      data?: {
        token: PostMessageParam;
        isRskIframe: boolean;
      }
    ) => {
      iframe.src = url;
      _token = data?.token ?? _token;
      _isRskIframe = data?.isRskIframe ?? _isRskIframe;
    },
  };

  const api = ["signTypedData", "getAddress"] as const;
  for (const method of api) {
    sdk[method] = (data: unknown) => {
      return new Promise((resolve, reject) => {
        const type = `rsk-${method}`;
        rskWindow.postMessage({ type, data }, "*");
        responsePromise(type).then(resolve).catch(reject);
      });
    };
  }

  return sdk;
};
