import {
  type FirebaseConfig,
  type InitFirebaseResponse,
  initFirebase,
} from "../../../packages/utils/firebase";

export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useRuntimeConfig().public;
  const initialize = async () => {
    const userClerkStore = useClerkUserStore();
    if (userClerkStore.userNanoid || userClerkStore.user?.id) {
      window.removeEventListener("click", initialize);
      const firebase = await initFirebase(
        config.firebase as unknown as FirebaseConfig,
        config.appUrl,
        userClerkStore.userNanoid ?? userClerkStore.user?.id
      );

      const app = nuxtApp.vueApp;
      app.provide("firebase", firebase);
      app.config.globalProperties.$firebase = firebase;
    }
  };
  window.addEventListener("click", initialize, { once: true });

  const channel = new BroadcastChannel("push-notifications");
  channel.onmessage = (message) => {
    const notificationStore = useNotificationsStore();
    notificationStore.addPushNotification(message.data);
    console.log("received push notification", message.data.eventName);
  };
});

export type { InitFirebaseResponse };
