import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from 'postcode-validator'

const campus: Record<string, string[]> = {
  andover: ['10', '12'],
  atlanta: ['60', '67'],
  austin: ['50', '53'],
  brookhaven: [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '11',
    '13',
    '14',
    '16',
    '21',
    '22',
    '23',
    '25',
    '34',
    '51',
    '52',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
    '65',
  ],
  cincinnati: ['30', '32', '35', '36', '37', '38', '61'],
  fresno: ['15', '24'],
  internet: ['20', '26', '27', '45', '46', '47', '81', '82', '83', '84', '85'],
  kansas: ['40', '44'],
  memphis: ['94', '95'],
  ogden: ['80', '90'],
  philadelphia: [
    '33',
    '39',
    '41',
    '42',
    '43',
    '46',
    '48',
    '62',
    '63',
    '64',
    '66',
    '68',
    '71',
    '72',
    '73',
    '74',
    '75',
    '76',
    '77',
    '86',
    '87',
    '88',
    '91',
    '92',
    '93',
    '98',
    '99',
  ],
  sba: ['31'],
}

/**
 * Cache all available prefixes.
 */

const prefixes: string[] = []

for (const location in campus) {
  const value = campus[location]
  if (value) prefixes.push(...value)
}

prefixes.sort()

const ussnRegex =
  /^(?!666|000|9\d{2})\d{3}[- ]{0,1}(?!00)\d{2}[- ]{0,1}(?!0{4})\d{4}$/
export const isUSSSN = (value: string) => {
  const blacklist = ['078051120', '219099999', '457555462']
  if (!ussnRegex.test(value)) return false
  return blacklist.indexOf(value.replace(/\D/g, '')) === -1
}

const einRegex = /^\d{2}[- ]{0,1}\d{7}$/
export function isEIN(value: string) {
  if (!einRegex.test(value)) {
    return false
  }
  return prefixes.indexOf(value.substr(0, 2)) !== -1
}

const itinRegex =
  /^(9\d{2})[- ]{0,1}((7[0-9]{1}|8[0-8]{1})|(9[0-2]{1})|(9[4-9]{1}))[- ]{0,1}(\d{4})$/
export function isITIN(value: string) {
  return itinRegex.test(value)
}

const repeatedNumbers = Array.from({ length: 10 }, (_, i) => i).map((current) =>
  String(current).repeat(9),
)

/**
 * Excludes ascending and descending sequence as TIN e.g. 123456789.
 */

const sequence = Array.from({ length: 10 }, (_, i) => i)
  .reduce((acc, current) => acc + current, '')
  .repeat(2)
const reverseSequence = sequence.split('').reverse().join('')

export function isTIN(value: string) {
  const sanitizedValue = value.replace(/\D/g, '')

  if (repeatedNumbers.indexOf(sanitizedValue) !== -1) {
    return false
  }

  if (
    sequence.includes(sanitizedValue) ||
    reverseSequence.includes(sanitizedValue)
  ) {
    return false
  }

  return isUSSSN(value) || isITIN(value) || isEIN(value)
}

export const isPostalCode = (value: string, countryCode: string) => {
  const _countryCode = countryCode.toUpperCase()
  if (!postcodeValidatorExistsForCountry(_countryCode)) return true
  return postcodeValidator(value, _countryCode)
}

export const USStateCodes: { [key: string]: string } = {
  arizona: 'AZ',
  alabama: 'AL',
  alaska: 'AK',
  arkansas: 'AR',
  california: 'CA',
  colorado: 'CO',
  connecticut: 'CT',
  'district of columbia': 'DC',
  celaware: 'DE',
  florida: 'FL',
  georgia: 'GA',
  hawaii: 'HI',
  idaho: 'ID',
  illinois: 'IL',
  indiana: 'IN',
  iowa: 'IA',
  kansas: 'KS',
  kentucky: 'KY',
  louisiana: 'LA',
  maine: 'ME',
  maryland: 'MD',
  massachusetts: 'MA',
  michigan: 'MI',
  minnesota: 'MN',
  mississippi: 'MS',
  missouri: 'MO',
  montana: 'MT',
  nebraska: 'NE',
  nevada: 'NV',
  'new hampshire': 'NH',
  'new jersey': 'NJ',
  'new mexico': 'NM',
  'new york': 'NY',
  'north carolina': 'NC',
  'north dakota': 'ND',
  ohio: 'OH',
  oklahoma: 'OK',
  oregon: 'OR',
  pennsylvania: 'PA',
  'rhode island': 'RI',
  'south carolina': 'SC',
  'south dakota': 'SD',
  tennessee: 'TN',
  texas: 'TX',
  utah: 'UT',
  vermont: 'VT',
  virginia: 'VA',
  washington: 'WA',
  'west virginia': 'WV',
  wisconsin: 'WI',
  wyoming: 'WY',
}

export const getUSStateCode = (stateName: string) => {
  if (stateName.length === 2) return stateName
  return USStateCodes[stateName.toLowerCase().trim()]
}

export const isUSState = (stateCode: string) => {
  return Object.values(USStateCodes).includes(stateCode.toUpperCase())
}

/**
 * List of country codes with
 * corresponding IBAN regular expression
 * Reference: https://en.wikipedia.org/wiki/International_Bank_Account_Number
 */
const ibanRegexThroughCountryCode = {
  AD: /^(AD[0-9]{2})\d{8}[A-Z0-9]{12}$/,
  AE: /^(AE[0-9]{2})\d{3}\d{16}$/,
  AL: /^(AL[0-9]{2})\d{8}[A-Z0-9]{16}$/,
  AT: /^(AT[0-9]{2})\d{16}$/,
  AZ: /^(AZ[0-9]{2})[A-Z0-9]{4}\d{20}$/,
  BA: /^(BA[0-9]{2})\d{16}$/,
  BE: /^(BE[0-9]{2})\d{12}$/,
  BG: /^(BG[0-9]{2})[A-Z]{4}\d{6}[A-Z0-9]{8}$/,
  BH: /^(BH[0-9]{2})[A-Z]{4}[A-Z0-9]{14}$/,
  BR: /^(BR[0-9]{2})\d{23}[A-Z]{1}[A-Z0-9]{1}$/,
  BY: /^(BY[0-9]{2})[A-Z0-9]{4}\d{20}$/,
  CH: /^(CH[0-9]{2})\d{5}[A-Z0-9]{12}$/,
  CR: /^(CR[0-9]{2})\d{18}$/,
  CY: /^(CY[0-9]{2})\d{8}[A-Z0-9]{16}$/,
  CZ: /^(CZ[0-9]{2})\d{20}$/,
  DE: /^(DE[0-9]{2})\d{18}$/,
  DK: /^(DK[0-9]{2})\d{14}$/,
  DO: /^(DO[0-9]{2})[A-Z]{4}\d{20}$/,
  DZ: /^(DZ\d{24})$/,
  EE: /^(EE[0-9]{2})\d{16}$/,
  EG: /^(EG[0-9]{2})\d{25}$/,
  ES: /^(ES[0-9]{2})\d{20}$/,
  FI: /^(FI[0-9]{2})\d{14}$/,
  FO: /^(FO[0-9]{2})\d{14}$/,
  FR: /^(FR[0-9]{2})\d{10}[A-Z0-9]{11}\d{2}$/,
  GB: /^(GB[0-9]{2})[A-Z]{4}\d{14}$/,
  GE: /^(GE[0-9]{2})[A-Z0-9]{2}\d{16}$/,
  GI: /^(GI[0-9]{2})[A-Z]{4}[A-Z0-9]{15}$/,
  GL: /^(GL[0-9]{2})\d{14}$/,
  GR: /^(GR[0-9]{2})\d{7}[A-Z0-9]{16}$/,
  GT: /^(GT[0-9]{2})[A-Z0-9]{4}[A-Z0-9]{20}$/,
  HR: /^(HR[0-9]{2})\d{17}$/,
  HU: /^(HU[0-9]{2})\d{24}$/,
  IE: /^(IE[0-9]{2})[A-Z0-9]{4}\d{14}$/,
  IL: /^(IL[0-9]{2})\d{19}$/,
  IQ: /^(IQ[0-9]{2})[A-Z]{4}\d{15}$/,
  IR: /^(IR[0-9]{2})0\d{2}0\d{18}$/,
  IS: /^(IS[0-9]{2})\d{22}$/,
  IT: /^(IT[0-9]{2})[A-Z]{1}\d{10}[A-Z0-9]{12}$/,
  JO: /^(JO[0-9]{2})[A-Z]{4}\d{22}$/,
  KW: /^(KW[0-9]{2})[A-Z]{4}[A-Z0-9]{22}$/,
  KZ: /^(KZ[0-9]{2})\d{3}[A-Z0-9]{13}$/,
  LB: /^(LB[0-9]{2})\d{4}[A-Z0-9]{20}$/,
  LC: /^(LC[0-9]{2})[A-Z]{4}[A-Z0-9]{24}$/,
  LI: /^(LI[0-9]{2})\d{5}[A-Z0-9]{12}$/,
  LT: /^(LT[0-9]{2})\d{16}$/,
  LU: /^(LU[0-9]{2})\d{3}[A-Z0-9]{13}$/,
  LV: /^(LV[0-9]{2})[A-Z]{4}[A-Z0-9]{13}$/,
  MA: /^(MA[0-9]{26})$/,
  MC: /^(MC[0-9]{2})\d{10}[A-Z0-9]{11}\d{2}$/,
  MD: /^(MD[0-9]{2})[A-Z0-9]{20}$/,
  ME: /^(ME[0-9]{2})\d{18}$/,
  MK: /^(MK[0-9]{2})\d{3}[A-Z0-9]{10}\d{2}$/,
  MR: /^(MR[0-9]{2})\d{23}$/,
  MT: /^(MT[0-9]{2})[A-Z]{4}\d{5}[A-Z0-9]{18}$/,
  MU: /^(MU[0-9]{2})[A-Z]{4}\d{19}[A-Z]{3}$/,
  MZ: /^(MZ[0-9]{2})\d{21}$/,
  NL: /^(NL[0-9]{2})[A-Z]{4}\d{10}$/,
  NO: /^(NO[0-9]{2})\d{11}$/,
  PK: /^(PK[0-9]{2})[A-Z0-9]{4}\d{16}$/,
  PL: /^(PL[0-9]{2})\d{24}$/,
  PS: /^(PS[0-9]{2})[A-Z0-9]{4}\d{21}$/,
  PT: /^(PT[0-9]{2})\d{21}$/,
  QA: /^(QA[0-9]{2})[A-Z]{4}[A-Z0-9]{21}$/,
  RO: /^(RO[0-9]{2})[A-Z]{4}[A-Z0-9]{16}$/,
  RS: /^(RS[0-9]{2})\d{18}$/,
  SA: /^(SA[0-9]{2})\d{2}[A-Z0-9]{18}$/,
  SC: /^(SC[0-9]{2})[A-Z]{4}\d{20}[A-Z]{3}$/,
  SE: /^(SE[0-9]{2})\d{20}$/,
  SI: /^(SI[0-9]{2})\d{15}$/,
  SK: /^(SK[0-9]{2})\d{20}$/,
  SM: /^(SM[0-9]{2})[A-Z]{1}\d{10}[A-Z0-9]{12}$/,
  SV: /^(SV[0-9]{2})[A-Z0-9]{4}\d{20}$/,
  TL: /^(TL[0-9]{2})\d{19}$/,
  TN: /^(TN[0-9]{2})\d{20}$/,
  TR: /^(TR[0-9]{2})\d{5}[A-Z0-9]{17}$/,
  UA: /^(UA[0-9]{2})\d{6}[A-Z0-9]{19}$/,
  VA: /^(VA[0-9]{2})\d{18}$/,
  VG: /^(VG[0-9]{2})[A-Z]{4}\d{16}$/,
  XK: /^(XK[0-9]{2})\d{16}$/,
} as const

function hasValidIbanFormat(str: string) {
  const strippedStr = str.replace(/[\s-]+/gi, '').toUpperCase()
  const isoCountryCode = strippedStr.slice(0, 2).toUpperCase()

  const countryRegex =
    ibanRegexThroughCountryCode[
      isoCountryCode as keyof typeof ibanRegexThroughCountryCode
    ]
  if (!countryRegex) return false

  const isoCountryCodeInIbanRegexCodeObject =
    isoCountryCode in ibanRegexThroughCountryCode

  return isoCountryCodeInIbanRegexCodeObject && countryRegex.test(strippedStr)
}

function hasValidIbanChecksum(str: string) {
  const strippedStr = str.replace(/[^A-Z0-9]+/gi, '').toUpperCase() // Keep only digits and A-Z latin alphabetic
  const rearranged = strippedStr.slice(4) + strippedStr.slice(0, 4)
  const alphaCapsReplacedWithDigits = rearranged.replace(
    /[A-Z]/g,
    (char: string) => String(char.charCodeAt(0) - 55),
  )

  const remainder = alphaCapsReplacedWithDigits
    .match(/\d{1,7}/g)
    ?.reduce((acc: number, value: string) => Number(acc + value) % 97, 0)

  return remainder === 1
}

export const isIBAN = (str: string) => {
  return hasValidIbanFormat(str) && hasValidIbanChecksum(str)
}
const numericRegex = /^[+-]?([0-9]*[.}])?[0-9]+$/
export const isNumeric = (str: string) => {
  return numericRegex.test(str)
}
