import {
  generateAuthenticationOptions,
  generateRegistrationOptions,
} from "@simplewebauthn/server";
import {
  startAuthentication,
  startRegistration,
} from "@simplewebauthn/browser";

function getRootDomain(hostname: string) {
  try {
    const parts = hostname.split(".");
    if (parts.length > 2) {
      const tldLength = parts[parts.length - 2].length <= 3 ? 3 : 2;
      return parts.slice(-tldLength).join(".");
    }

    return hostname;
  } catch (error) {
    console.error("Invalid URL:", error);
    throw new Error(
      `Invalid URL: unable to retrieve root domain from hostname "${hostname}"`
    );
  }
}

export async function generateKey(email: string) {
  const registrationOptions = await generateRegistrationOptions({
    rpName: "Rise Security Key Manager",
    rpID: getRootDomain(window.location.hostname),
    userID: `RSK - ${email}`,
    userName: `RSK - ${email}`,
  });

  const authn = await startRegistration(registrationOptions);
  return authn.id;
}

export async function getKey() {
  const options = await generateAuthenticationOptions({
    userVerification: "preferred",
    rpID: getRootDomain(window.location.hostname),
  });
  const { id } = await startAuthentication(options);
  return id;
}
