import { z } from 'zod'
import { userNanoid } from '../../brands.js'
import { recaptcha, requestAttributes } from '../../formats.js'
import { defaultErrorResponses, defaultResponse } from '../../response.js'
import type { Schema } from '../../types.js'

const postUserSessionBody = z.object({
  clerk_session_id: z.string().min(1),
})

const userSessionSchema = z.object({
  started_at: z.string().datetime(),
  last_updated_at: z.string().datetime().nullable(),
  nanoid: userNanoid,
  country: z.string(),
  user_agent: z.string(),
  ip_address: z.string(),
  requests: z
    .object({
      created_at: z.string().datetime(),
      description: z.string(),
      method: z.string(),
      url: z.string(),
      status: z.number(),
      attributes: requestAttributes.nullable(),
    })
    .array(),
})

export const schema = {
  '/dashboard/user_sessions': {
    post: {
      tags: ['Dashboard'],
      security: [{}],
      summary: 'Upsert a user session',
      headers: recaptcha,
      body: postUserSessionBody,
      response: {
        200: defaultResponse,
        ...defaultErrorResponses,
      },
    },
  },
  '/dashboard/user_sessions/latest': {
    get: {
      tags: ['Dashboard'],
      security: [{}],
      summary: 'Get the latest user session',
      response: {
        200: defaultResponse.extend({
          data: userSessionSchema,
        }),
        ...defaultErrorResponses,
      },
    },
  },
} as const satisfies Schema
