import { z } from 'zod'

const zodCustomIssue = z.object({
  code: z.literal('custom'),
  message: z.string(),
  path: z.array(z.union([z.string(), z.number()])),
})

const zodInvalidTypeIssue = z.object({
  code: z.literal('invalid_type'),
  expected: z.string(),
  received: z.string(),
  path: z.array(z.union([z.string(), z.number()])),
  message: z.string(),
})

const zodInvalidLiteralIssue = z.object({
  code: z.literal('invalid_literal'),
  expected: z.any(),
  received: z.any(),
  path: z.array(z.union([z.string(), z.number()])),
  message: z.string(),
})

const zodUnrecognizedKeysIssue = z.object({
  code: z.literal('unrecognized_keys'),
  keys: z.array(z.string()),
  path: z.array(z.union([z.string(), z.number()])),
  message: z.string(),
})

const zodInvalidUnionIssue = z.object({
  code: z.literal('invalid_union'),
  unionErrors: z.array(z.any()),
  path: z.array(z.union([z.string(), z.number()])),
  message: z.string(),
})

const zodInvalidEnumValueIssue = z.object({
  code: z.literal('invalid_enum_value'),
  options: z.array(z.any()),
  received: z.any(),
  path: z.array(z.union([z.string(), z.number()])),
  message: z.string(),
})

const zodInvalidArgumentsIssue = z.object({
  code: z.literal('invalid_arguments'),
  path: z.array(z.union([z.string(), z.number()])),
  message: z.string(),
})

const zodInvalidReturnTypeIssue = z.object({
  code: z.literal('invalid_return_type'),
  path: z.array(z.union([z.string(), z.number()])),
  message: z.string(),
})

const zodInvalidDateIssue = z.object({
  code: z.literal('invalid_date'),
  path: z.array(z.union([z.string(), z.number()])),
  message: z.string(),
})

const zodInvalidStringIssue = z.object({
  code: z.literal('invalid_string'),
  validation: z.enum([
    'email',
    'url',
    'uuid',
    'cuid',
    'regex',
    'trim',
    'min',
    'max',
    'length',
  ]),
  path: z.array(z.union([z.string(), z.number()])),
  message: z.string(),
})

const zodTooSmallIssue = z.object({
  code: z.literal('too_small'),
  minimum: z.number(),
  type: z.enum(['array', 'string', 'number', 'set']),
  inclusive: z.boolean(),
  exact: z.boolean().optional(),
  path: z.array(z.union([z.string(), z.number()])),
  message: z.string(),
})

const zodTooBigIssue = z.object({
  code: z.literal('too_big'),
  maximum: z.number(),
  type: z.enum(['array', 'string', 'number', 'set']),
  inclusive: z.boolean(),
  exact: z.boolean().optional(),
  path: z.array(z.union([z.string(), z.number()])),
  message: z.string(),
})

// Added missing issue types
const zodInvalidIntersectionTypesIssue = z.object({
  code: z.literal('invalid_intersection_types'),
  path: z.array(z.union([z.string(), z.number()])),
  message: z.string(),
})

const zodNotMultipleOfIssue = z.object({
  code: z.literal('not_multiple_of'),
  multipleOf: z.number(),
  path: z.array(z.union([z.string(), z.number()])),
  message: z.string(),
})

const zodNotFiniteIssue = z.object({
  code: z.literal('not_finite'),
  path: z.array(z.union([z.string(), z.number()])),
  message: z.string(),
})

const zodInvalidDiscriminatorIssue = z.object({
  code: z.literal('invalid_discriminator'),
  options: z.array(z.string()),
  path: z.array(z.union([z.string(), z.number()])),
  message: z.string(),
})

export const zodIssue = z.discriminatedUnion('code', [
  zodCustomIssue,
  zodInvalidTypeIssue,
  zodInvalidLiteralIssue,
  zodUnrecognizedKeysIssue,
  zodInvalidUnionIssue,
  zodInvalidEnumValueIssue,
  zodInvalidArgumentsIssue,
  zodInvalidReturnTypeIssue,
  zodInvalidDateIssue,
  zodInvalidStringIssue,
  zodTooSmallIssue,
  zodTooBigIssue,
  zodInvalidIntersectionTypesIssue,
  zodNotMultipleOfIssue,
  zodNotFiniteIssue,
  zodInvalidDiscriminatorIssue,
])
export const zodIssues = z.array(zodIssue)
export type ZodIssues = z.infer<typeof zodIssues>
export type ZodIssue = z.infer<typeof zodIssue>

export const defaultResponse = z.object({
  success: z.literal(true),
})
export const defaultError = defaultResponse.extend({
  success: z.literal(false),
  data: z.string(),
  zod: z.any().optional(),
})

export const defaultErrorResponses = {
  400: defaultError.describe('Bad Request'),
  401: defaultError.describe('Unauthorized'),
  403: defaultError.describe('Forbidden'),
  404: defaultError.describe('Not Found'),
  500: defaultError.describe('Internal Server Error'),
}
