import { z } from 'zod'
import {
  companyNanoid,
  inviteNanoid,
  teamNanoid,
  transactionNanoid,
} from '../../brands.js'
import { invites } from '../../codegen/zod/rise/invites.js'
import {
  blockchainAddress,
  blockchainSignature,
  recaptcha,
} from '../../formats.js'
import {
  riseIDForwarderSetRolesTypedDataResponse,
  riseIDForwarderSetRolesValuesSchema,
} from '../../forwardersTypedDataSchemas.js'
import { defaultErrorResponses, defaultResponse } from '../../response.js'
import type { Schema } from '../../types.js'

export const prefill = z.object({
  first_name: z.string().min(1),
  middle_name: z.string().optional(),
  last_name: z.string().min(1),
  dob: z.string().optional(),
  line_1: z.string().optional(),
  line_2: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  country: z.string().optional(),
  zip_code: z.string().optional(),
  timezone: z.string().optional(),
})

const postInvitesBody = z.object({
  anonymous: z.boolean(),
  role: z.enum(['contractor', 'team_employee']),
  emails: z.array(z.string().email().trim()).min(1).max(100),
  nanoid: teamNanoid,
})

export const inviteTeamManagerRoles = z.enum([
  'team_admin',
  'team_finance_admin',
  'team_viewer',
])
export const inviteCompanyManagerRoles = z.enum([
  'org_admin',
  'org_finance_admin',
  'org_viewer',
])
export const inviteManagerRoles = inviteTeamManagerRoles.or(
  inviteCompanyManagerRoles,
)
const postInvitesManagerBody = z.object({
  emails: z.string().email().array().min(1).max(100),
  role: inviteManagerRoles,
  nanoid: teamNanoid.or(companyNanoid),
  prefills: prefill.partial().array().min(1).max(100).optional(),
})

const getInviteResponse = defaultResponse.extend({
  data: z.object({
    role: invites.shape.role,
    email: z.string(),
    team_name: z.string().nullable(),
    company_name: z.string().nullable(),
    inviter_name: z.string().min(1),
    prefill: prefill.partial(),
  }),
})

const postWarmInviteBody = z.object({
  anonymous: z.boolean(),
  role: z.enum(['contractor', 'team_employee']),
  nanoid: teamNanoid,
  email: z.string().email(),
  prefill,
})

const postBatchInvitesBody = z.object({
  anonymous: z.boolean(),
  role: z.enum(['contractor', 'team_employee']),
  nanoid: teamNanoid,
  invites: z
    .object({
      email: z.string().email(),
      first_name: z.string().min(1),
      middle_name: z.string(),
      last_name: z.string().min(1),
      dob: z.string(),
      line_1: z.string(),
      line_2: z.string(),
      city: z.string(),
      state: z.string(),
      country: z.string(),
      zip_code: z.string(),
      timezone: z.string(),
    })
    .array(),
})

export const schema = {
  '/dashboard/invites/:nanoid': {
    get: {
      tags: ['Dashboard'],
      security: [{ bearerAuth: [] }],
      summary: 'Get invite by nanoid',
      params: z.object({
        nanoid: inviteNanoid,
      }),
      querystring: z.object({
        secret: z.string().min(1),
      }),
      response: {
        200: getInviteResponse,
        ...defaultErrorResponses,
      },
    },
    delete: {
      tags: ['Dashboard'],
      security: [{ bearerAuth: [] }],
      summary: 'Cancel invites',
      headers: recaptcha,
      params: z.object({
        nanoid: inviteNanoid,
      }),
      response: {
        200: defaultResponse,
        ...defaultErrorResponses,
      },
    },
  },
  '/dashboard/invites/manager': {
    post: {
      tags: ['Dashboard'],
      security: [{ bearerAuth: [] }],
      summary: 'Create invites',
      headers: recaptcha,
      body: postInvitesManagerBody,
      response: {
        200: defaultResponse.extend({
          data: z.object({
            invites: inviteNanoid.array(),
            typed_data: riseIDForwarderSetRolesTypedDataResponse,
          }),
        }),
        ...defaultErrorResponses,
      },
    },
    put: {
      tags: ['Dashboard'],
      security: [{ bearerAuth: [] }],
      summary: 'Create invites',
      headers: recaptcha,
      body: z.object({
        invites: inviteNanoid.array(),
        signer: blockchainAddress,
        typed_data: riseIDForwarderSetRolesValuesSchema,
        signature: blockchainSignature,
      }),
      response: {
        200: defaultResponse.extend({
          data: z.object({
            invites: inviteNanoid.array(),
            transaction: transactionNanoid,
          }),
        }),
        ...defaultErrorResponses,
      },
    },
  },
  '/dashboard/invites': {
    post: {
      tags: ['Dashboard'],
      security: [{ bearerAuth: [] }],
      summary: 'Create invites',
      headers: recaptcha,
      body: postInvitesBody,
      response: {
        200: defaultResponse.extend({
          data: z.object({
            nanoids: z.string().array(),
          }),
        }),
        ...defaultErrorResponses,
      },
    },
  },
  '/dashboard/invites/warm': {
    post: {
      tags: ['Dashboard'],
      security: [{ bearerAuth: [] }],
      summary: 'Warm up invites',
      headers: recaptcha,
      body: postWarmInviteBody,
      response: {
        200: defaultResponse,
        ...defaultErrorResponses,
      },
    },
  },
  '/dashboard/invites/batch': {
    post: {
      tags: ['Dashboard'],
      security: [{ bearerAuth: [] }],
      summary: 'Create invites in batch',
      headers: recaptcha,
      body: postBatchInvitesBody,
      response: {
        200: defaultResponse,
        ...defaultErrorResponses,
      },
    },
  },
  '/dashboard/invites/resend/:nanoid': {
    post: {
      tags: ['Dashboard'],
      security: [{ bearerAuth: [] }],
      summary: 'Resend invite by nanoid',
      params: z.object({
        nanoid: inviteNanoid,
      }),
      headers: recaptcha,
      response: {
        200: defaultResponse,
        ...defaultErrorResponses,
      },
    },
  },
  '/dashboard/invites/reminder/:nanoid': {
    post: {
      tags: ['Dashboard'],
      security: [{ bearerAuth: [] }],
      summary: 'Resend invite by nanoid',
      params: z.object({
        nanoid: inviteNanoid,
      }),
      headers: recaptcha,
      response: {
        200: defaultResponse,
        ...defaultErrorResponses,
      },
    },
  },
} as const satisfies Schema
