import {
  getAllCountries,
  getAllTimezones,
  getTimezonesForCountry,
} from "countries-and-timezones";
import { defineStore } from "pinia";

export const useCountriesStore = defineStore("countries", {
  state: (): {
    countriesItems: { label: string; value: string }[];
    timezonesItems: { label: string; value: string }[];
    countriesMap: Record<string, string>;
  } => ({
    countriesItems: [],
    timezonesItems: [],
    countriesMap: {},
  }),

  actions: {
    getTimezones(county?: string) {
      const timezones =
        (county && getTimezonesForCountry(county)) ||
        Object.values(getAllTimezones());

      this.timezonesItems = timezones.map((timezone) => {
        return {
          label: `${timezone.name.replace("_", " ")} ${timezone.utcOffsetStr}`,
          value: `${timezone.name.replace("_", " ")} ${timezone.utcOffsetStr}`,
        };
      });
    },
    getCountries() {
      const countries = getAllCountries();
      const countriesKeys = Object.keys(countries);

      this.countriesMap = countriesKeys.reduce(
        (acc: Record<string, string>, country: string) => {
          acc[countries[country as keyof typeof countries].id] =
            countries[country as keyof typeof countries].name;
          return acc;
        },
        {}
      );

      this.countriesItems = countriesKeys.map((country: string) => {
        return {
          label: countries[country as keyof typeof countries].name,
          value: countries[country as keyof typeof countries].id,
        };
      });
    },
  },
});
